<template>
  <div>
    <PageHeaderLayout>
      <div class='main-page-content'>
        <el-row :gutter='15'>
          <el-col :md='24' :xs='24'>
            <el-card shadow='hover'>
              <template slot='header'>
                <span>Redis信息 <el-link type='primary' icon='el-icon-refresh' @click='getService'></el-link></span>
              </template>
              <div class='table'>
                <el-row :gutter='15'>
                  <el-col :span='18'>
                    <table cellspacing='0' style='width: 100%;'>
                      <tbody>
                      <tr>
                        <td>
                          <div class='cell'>Redis版本</div>
                        </td>
                        <td>
                          <div class='cell'>{{ server.version }}</div>
                        </td>
                        <td>
                          <div class='cell'>客户端连接数</div>
                        </td>
                        <td>
                          <div class='cell'>{{ server.clients }}</div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div class='cell'>运行模式</div>
                        </td>
                        <td>
                          <div class='cell'>{{ server.redis_mode }}</div>
                        </td>
                        <td>
                          <div class='cell'>运行天数</div>
                        </td>
                        <td>
                          <div class='cell'>{{ server.run_days }}</div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div class='cell'>端口</div>
                        </td>
                        <td>
                          <div class='cell'>{{ server.port }}</div>
                        </td>
                        <td>
                          <div class='cell'>AOF状态</div>
                        </td>
                        <td>
                          <div class='cell'>{{ server.aof_enabled }}</div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div class='cell'>已过期key</div>
                        </td>
                        <td>
                          <div class='cell'>{{ server.expired_keys }}</div>
                        </td>
                        <td>
                          <div class='cell'>系统使用key</div>
                        </td>
                        <td>
                          <div class='cell'>{{ server.sys_total_keys }}</div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div class='cell'>Redis分配的内存总量(mb)</div>
                        </td>
                        <td>
                          <div class='cell'>{{ server.used_memory_human }}</div>
                        </td>
                        <td>
                          <div class='cell'>向操作系统申请的内存大小(mb)</div>
                        </td>
                        <td>
                          <div class='cell'>{{ server.used_memory_rss_human }}</div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div class='cell'>redis的内存消耗峰值(mb)</div>
                        </td>
                        <td>
                          <div class='cell'>{{ server.used_memory_peak_human }}</div>
                        </td>
                        <td>
                          <div class='cell' title='使用内存与峰值内存的百分比(used_memory / used_memory_peak) *100%'>使用内存与峰值内存的百分比
                          </div>
                        </td>
                        <td>
                          <div class='cell'>{{ server.used_memory_peak_perc }}</div>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </el-col>

                  <el-col :span='6' class='cache-col'>
                    <el-progress type='dashboard' :percentage='100' :stroke-width='8' :width='170' status='success'>
                      <template #default>
                        <span class='percentage-value'>{{ server.use_memory }}</span>
                        <span class='percentage-label'>Memory</span>
                      </template>
                    </el-progress>
                  </el-col>
                </el-row>
              </div>
            </el-card>
            <br />
            <el-card shadow='hover'>
              <template slot='header'>
                <span>其他信息</span>
                <el-button style='float: right;' type='primary' icon='el-icon-refresh' @click='clear'>清空缓存</el-button>
              </template>
              <div class='table'>
                <el-row :gutter='25'>
                  <el-col :span='12'>
                    <div class='cell'>Redis库：
                      <el-link type='primary'><span class='el-icon-loading' v-if='loading'></span> {{ redis_db }}({{ keys.length || 0 }} 个缓存)</el-link>
                      <el-radio-group v-model='redis_db' @change='showKeys'>
                        <el-radio-button :label='db' :key='index' v-for='(db,index) in db_arr'></el-radio-button>
                      </el-radio-group>
                    </div>
                    <div   v-loading='loading'
                           element-loading-text='玩命加载中……'
                           element-loading-spinner='el-icon-loading'>
                      <el-table :data='tableData' :loading='loading' max-height='400'>
                        <el-table-column label='序号' type='index' width='100'></el-table-column>
                        <el-table-column label='缓存名称' prop='key_name' sortable></el-table-column>
                        <el-table-column label='操作' width='200'>
                          <template slot-scope='{row}'>
                            <el-button type='danger' @click='deleteKey(row.key)' icon='el-icon-delete'>
                              删除
                            </el-button>
                            <el-button type='primary' @click='view(row.key_name)' icon='el-icon-view'>
                              查看
                            </el-button>
                          </template>
                        </el-table-column>
                      </el-table>
                    </div>

                    <!--                    <table cellspacing='0' style='width: 100%;'>-->
                    <!--                      <thead>-->
                    <!--                      <tr>-->
                    <!--                        <td>-->
                    <!--                          <div class='cell' width='5%'>&nbsp;</div>-->
                    <!--                        </td>-->
                    <!--                        <td>-->
                    <!--                          <div class='cell'>缓存名称</div>-->
                    <!--                        </td>-->
                    <!--                        <td>-->
                    <!--                          <div class='cell' width='130' style='text-align:center'>操作</div>-->
                    <!--                        </td>-->
                    <!--                      </tr>-->
                    <!--                      </thead>-->
                    <!--                      <tbody>-->
                    <!--                      <tr v-for='(key, index) in keys' :key='index'>-->
                    <!--                        <td>-->
                    <!--                          <div class='cell' width='5%'>{{ index + 1 }}</div>-->
                    <!--                        </td>-->
                    <!--                        <td>-->
                    <!--                          <div class='cell'>{{ key }}</div>-->
                    <!--                        </td>-->
                    <!--                        <td>-->
                    <!--                          <div class='cell' width='130' style='text-align:center'>-->
                    <!--                            <el-button type='danger' @click='deleteKey(key)' icon='el-icon-delete'>-->
                    <!--                              删除-->
                    <!--                            </el-button>-->
                    <!--                            <el-button type='primary' @click='view(key)' icon='el-icon-view'>-->
                    <!--                              查看-->
                    <!--                            </el-button>-->
                    <!--                          </div>-->
                    <!--                        </td>-->
                    <!--                      </tr>-->
                    <!--                      </tbody>-->
                    <!--                    </table>-->
                  </el-col>
                  <el-col :span='12'>
                    <div style='line-height: 38px; font-size: 14px;'>Key详情</div>
                    <el-input type='textarea' :rows='20' v-model='keyContent' />
                  </el-col>
                </el-row>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </div>
    </PageHeaderLayout>
  </div>
</template>
<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import scEcharts from '@/components/scEcharts'

export default {
  name: 'systemCache',

  components: {
    scEcharts, PageHeaderLayout
  },
  computed: {
    tableData() {
      return this.keys.map((key_name) => {
        return { 'key_name': key_name }
      })
    }
  },

  data() {
    return {
      server: {},
      keys: [],
      keyContent: '',
      loading: false,
      redis_db: 0,
      db_arr: []
    }
  },

  async mounted() {
    for (let i = 0; i <16; i++) {
      this.db_arr.push(i)
    }

    await this.getService()
  },

  methods: {
    async getService() {
      await this.$api.getCacheInfo().then(data => {
        this.server = data.server
        this.keys = data.keys
        this.redis_db = data.redis_db
      })
    },

    showKeys() {
      this.loading = true
      this.$api.getCacheDbKeys(this.redis_db).then((data) => {
        this.keys = data.keys
        this.loading = false
      })
    },

    // 查看key
    view(key) {
      this.$api.viewCacheInfo(key,this.redis_db).then(data => {
        if (data.content)
          this.keyContent = data.content
      })
    },

    // 删除一个缓存
    deleteKey(key) {
      this.$api.delCacheKey(key,this.redis_db).then(() => {
        this.$message.success('删除成功')
      })
    },

    // 清空缓存
    clear() {
      this.$api.clearCache(this.redis_db).then(() => {
        this.$message.success('清空成功')
        this.showKeys()
      })
    }
  }
}
</script>

<style scoped lang='scss'>

//:deep(.el-progress__text span) {
//  font-size: 32px;
//}

.table tr {
  font-size: 14px;
  color: #606266;

}

.table td {
  box-sizing: border-box;
  text-overflow: ellipsis;
  text-align: left;
  vertical-align: middle;
  position: relative;
  border-bottom: 1px solid #ebeef5;
  padding: 10px 0;
}

.percentage-value {
  display: block;
  font-size: 24px;
  font-weight: bold;
}

.percentage-value em {
  font-size: 14px;
  font-style: normal;
  margin-left: 5px;
  font-weight: normal;
}

.percentage-label {
  font-size: 14px !important;
  line-height: 25px !important;
}

.cache-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.dark {
  .table tbody tr {
    color: #ddd;
  }

  .table tbody td, .table thead td {
    border-bottom: 1px solid #585858;
  }
}
</style>
